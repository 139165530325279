import { RouteActive } from '@kaa/common/types';
import { i18nKeys } from '@kaa/i18n/customers/keys';
import { TFunction } from 'i18next';
import { dataTest } from '../../datatest/keys';
import { Routes } from '../../routes';
import { WlFunctionHeaderTab } from './WlFunctionalHeader.types';

export const getDefaultTabs = (t: TFunction) => [
  {
    id: Routes.DASHBOARD,
    header: t(i18nKeys.navigation.dashboard),
    uid: '1',
  },
  {
    id: Routes.SERVICES_VOUCHERS,
    header: t(i18nKeys.navigation.serviceVouchers),
    children: [
      {
        id: Routes.SERVICES_VOUCHERS_WALLET,
        header: t(i18nKeys.navigation.wallet),
        uid: '1',
        'data-testid': dataTest.header.tabWallet,
      },
      {
        id: Routes.SERVICES_VOUCHERS_ORDER,
        header: t(i18nKeys.navigation.orders),
        uid: '2',
        'data-testid': dataTest.header.tabMyOrder,
      },
    ],
    uid: '2',
  },
  {
    id: Routes.PRESTATIONS,
    header: t(i18nKeys.navigation.prestations),
    children: [
      {
        id: Routes.PRESTATIONS_WORKS_MANAGEMENT,
        'data-testid': dataTest.header.tabPrestationManagement,
        header: t(i18nKeys.navigation.prestations),
        uid: '1',
      },
      {
        id: Routes.PRESTATIONS_WORKS_MANAGEMENT_HISTORY,
        'data-testid': dataTest.header.tabPrestationManagementHistory,
        header: t(i18nKeys.navigation.worksHistory),
        uid: '2',
      },
      {
        id: Routes.PRESTATIONS_PLAN_A_LEAVE,
        'data-testid': dataTest.header.tabLeave,
        header: t(i18nKeys.navigation.planALeave),
        uid: '3',
      },
    ],
    uid: '3',
  },
  {
    id: Routes.ACCOUNT,
    header: t(i18nKeys.navigation.account),
    children: [
      {
        id: Routes.ACCOUNT_PROFILE,
        'data-testid': dataTest.header.tabAccount,
        header: t(i18nKeys.navigation.profile),
        uid: '1',
      },
      {
        id: Routes.ACCOUNT_PREFERENCES,
        'data-testid': dataTest.header.tabPreferences,
        header: t(i18nKeys.navigation.preferences),
        uid: '2',
      },
      {
        id: Routes.ACCOUNT_FISCAL_ATTESTATION,
        'data-testid': dataTest.header.tabFiscalAttestation,
        header: t(i18nKeys.navigation.fiscalAttestation),
        uid: '3',
      },
    ],
    uid: '4',
  },
];

export const getLoginTabs = (t: TFunction) => [
  {
    id: Routes.LOGIN,
    header: t(i18nKeys.navigation.login),
    uid: '1',
  },
];

export const getRegisterTabs = (t: TFunction) => [
  {
    id: Routes.LOGIN,
    header: t(i18nKeys.navigation.backServiceVouchersLogin),
    uid: '1',
  },
  {
    id: Routes.REGISTER,
    header: t(i18nKeys.navigation.register),
    uid: '2',
    active: true,
  },
];

export const isActiveTab = (
  tab: {
    id: Routes;
    children?: Array<{ id: Routes }>;
    active?: boolean;
  },
  activeRoute: RouteActive<Routes>,
) =>
  tab.active ||
  tab.id === activeRoute.route.id ||
  (tab.children && tab.children.some((tab) => activeRoute.route.id === tab.id));

export const getTabs = (
  route: Routes,
  t: TFunction,
): WlFunctionHeaderTab[] | undefined => {
  const TABS: Partial<
    { [key in Routes]: (t: TFunction) => WlFunctionHeaderTab[] }
  > = {
    [Routes.DASHBOARD]: getDefaultTabs,
    [Routes.ACCOUNT]: getDefaultTabs,
    [Routes.ACCOUNT_PROFILE]: getDefaultTabs,
    [Routes.ACCOUNT_PREFERENCES]: getDefaultTabs,
    [Routes.ACCOUNT_FISCAL_ATTESTATION]: getDefaultTabs,
    [Routes.PRESTATIONS]: getDefaultTabs,
    [Routes.PRESTATIONS_WORKS_MANAGEMENT]: getDefaultTabs,
    [Routes.PRESTATIONS_WORKS_MANAGEMENT_HISTORY]: getDefaultTabs,
    [Routes.PRESTATIONS_PLAN_A_LEAVE]: getDefaultTabs,
    [Routes.SERVICES_VOUCHERS]: getDefaultTabs,
    [Routes.SERVICES_VOUCHERS_WALLET]: getDefaultTabs,
    [Routes.SERVICES_VOUCHERS_ORDER]: getDefaultTabs,
    [Routes.SERVICES_VOUCHERS_ORDER_DETAILS]: getDefaultTabs,
    [Routes.SERVICES_VOUCHERS_ORDER_PENDING]: getDefaultTabs,
    [Routes.LANGUAGE_REQUEST]: getDefaultTabs,
    [Routes.REGISTER]: getRegisterTabs,
    [Routes.REGISTER_COMPLETE]: getRegisterTabs,
    [Routes.REGISTER_SUMMARY]: getRegisterTabs,
    [Routes.LOGIN]: getLoginTabs,
  };

  const tab = TABS[route];

  return tab ? tab(t) : tab;
};
