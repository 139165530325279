import { AvailableLanguages, StorageKeys } from '@kaa/common/enums';
import { Settings } from 'luxon';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const useLanguage = () => {
  const { i18n } = useTranslation();
  const [language, setLanguage] = useState<AvailableLanguages>(
    i18n.language as AvailableLanguages,
  );

  const changeLanguage = useCallback(
    (lng: AvailableLanguages) => {
      i18n.changeLanguage(lng);
      Settings.defaultLocale = lng;
      localStorage.setItem(StorageKeys.LAST_SELECTED_LANGUAGE, lng);
      updateLocalesInUrlParams(lng);
    },
    [i18n],
  );

  useEffect(() => {
    setLanguage(i18n.language as AvailableLanguages);
  }, [i18n.language]);

  useEffect(() => {
    document.documentElement.setAttribute('lang', language);
  }, [language]);

  return { language, changeLanguage };
};

// This function is used to update the ui_locales in the ReturnUrl parameter on IDP login page
const updateLocalesInUrlParams = (selectedLanguage: AvailableLanguages) => {
  const url = new URL(window.location.href);
  const returnUrl = url.searchParams.get('ReturnUrl');

  if (returnUrl) {
    const decodedReturnUrl = decodeURIComponent(returnUrl);
    const returnUrlObj = new URL(decodedReturnUrl, window.location.origin);
    returnUrlObj.searchParams.set('ui_locales', selectedLanguage);

    // Remove the origin from the ReturnUrl to make it relative
    const newReturnUrl = returnUrlObj
      .toString()
      .replace(window.location.origin, '');

    url.searchParams.set('ReturnUrl', newReturnUrl);
    window.history.replaceState(null, '', url.toString());
  }
};
