import { RegionCode } from '@kaa/api/customers';
import { AvailableLanguages } from '@kaa/common/enums';
import {
  HeaderLogo,
  HeaderMenuButton,
  HeaderSupport,
  HeaderSwitchLanguage,
  HeaderWallonia,
} from '@kaa/core-app/common/components/wallonia/header/';
import { ActiveRouteProvider } from '@kaa/core-app/customers/components';
import { WlFunctionalHeader } from '@kaa/core-app/customers/components/functional-header-wl/WlFunctionalHeader';
import {
  REGION_CONTACT_A_PROVIDER_URL,
  REGION_CONTACT_US_URL,
  REGION_FAQ_URL,
  PASS_URL,
} from '@kaa/core-app/customers/constants';
import { getPath, Routes } from '@kaa/core-app/customers/routes';
import { useLanguage } from '@kaa/core-app/idp/utils';
import { i18nKeys } from '@kaa/i18n/customers/keys';
import { Icon } from '@kaa/ui-flanders/components';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HeaderLoginLink } from './header/HeaderLoginLink';
import { HeaderSwitchProfile } from './header/HeaderSwitchProfile';

type HeaderProps = {
  regionCode: RegionCode;
  languages: AvailableLanguages[];
};

export const Header = ({ regionCode, languages }: HeaderProps) => {
  const [mobileNav, showMobileNav] = useState(false);

  const { language, changeLanguage } = useLanguage();
  const { t } = useTranslation();

  return (
    <ActiveRouteProvider>
      {({ activeRoute }) => (
        <HeaderWallonia
          key={language}
          mobileNav={mobileNav}
          functionalHeader={
            <WlFunctionalHeader
              closeNav={() => showMobileNav(false)}
              activeRoute={activeRoute}
            />
          }
        >
          <HeaderLogo
            publicUrl={t(i18nKeys.wl.brps.url)}
            homeUrl={getPath(Routes.DASHBOARD)}
            title={t(i18nKeys.wl.siteBar.title)}
            titleMobile={t(i18nKeys.navigation.title)}
            mainLogoAlt={t(i18nKeys.wl.brps.label)}
            logoAlt="Pluxee"
          />
          <HeaderSupport
            title={t(i18nKeys.header.support.title)}
            subtitle={t(i18nKeys.header.support.subtitle)}
            popoverTitle={t(i18nKeys.navigation.support)}
            popoverIcon={Icon.QUESTION_MARK_FILLED}
            links={[
              {
                icon: Icon.PHONE,
                url: `tel:${t(i18nKeys.wl.general.helpCenter.phone)}`,
                label: t(i18nKeys.header.support.contactList.callus.title, {
                  phoneNumber: t(i18nKeys.wl.general.helpCenter.phone),
                }),
                description: t(
                  i18nKeys.wl.header.support.contactList.callus.description,
                ),
              },
              {
                target: '_blank',
                icon: Icon.EMAIL,
                url: t(REGION_CONTACT_US_URL[regionCode]),
                label: t(i18nKeys.header.support.contactList.contactus.title),
                description: t(
                  i18nKeys.header.support.contactList.contactus.description,
                ),
              },
              {
                target: '_blank',
                icon: Icon.LOCATION,
                url: t(PASS_URL[regionCode]),
                label: t(i18nKeys.header.support.contactList.pass.title),
              },
              {
                target: '_blank',
                icon: Icon.SEARCH,
                url: t(REGION_CONTACT_A_PROVIDER_URL[regionCode]),
                label: t(
                  i18nKeys.header.support.contactList.findProvider.title,
                ),
                description: t(
                  i18nKeys.header.support.contactList.findProvider.description,
                ),
              },
              {
                target: '_blank',
                icon: Icon.QUESTION_MARK,
                url: t(REGION_FAQ_URL[regionCode]),
                label: t(i18nKeys.header.support.contactList.helpCenter.title),
                description: t(
                  i18nKeys.header.support.contactList.helpCenter.description,
                ),
              },
            ]}
          />
          <HeaderSwitchLanguage
            language={language}
            languages={languages}
            changeLanguage={changeLanguage}
          />
          {activeRoute?.route.id !== Routes.LOGIN && (
            <>
              <HeaderSwitchProfile />
              <HeaderLoginLink />
            </>
          )}
          <HeaderMenuButton
            aria-label={t(i18nKeys.a11y.ariaLabel.menu)}
            aria-expanded={mobileNav}
            onClick={() => showMobileNav(!mobileNav)}
          />
        </HeaderWallonia>
      )}
    </ActiveRouteProvider>
  );
};
